.Map-container {
    margin: auto;
    text-align: center;
    margin-top: 51px;
}
.Map-container svg {
    user-select: none;
}
.Map-container .Hint-container {
    overflow-y:auto;
    text-align: left;
    position: absolute;
    padding: 15px 15px;
    min-width: 300px;
    max-height: 270px;
    border-radius: 9px;
    background: rgba(255, 213, 189, 0.8);
    border: 1px solid rgba(255, 92, 0, 1)

}
.Map-container .Hint-container section{
    padding: 10px 0px;
}
.Map-container .Hint-container section h3, 
.Map-container .Hint-container section p{
    font-size: 14px;
    line-height: 20px;
}
.Map-container .Hint-container section .tel{
    margin-top: 15px;
}
.Map-container .Hint-container .bordered{
    border-top: 1px solid rgba(255, 128, 57, 1);
}

.Map-container .avalible-reg {
    cursor: pointer;
}

/* Работает в Firefox */
.Map-container .Hint-container{
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 128, 57, 1) rgba(255, 213, 189, 0.8);
}
  
/* Работает в Chrome, Edge и Safari */
.Map-container .Hint-container::-webkit-scrollbar {
    width: 12px;
}
  
.Map-container .Hint-container::-webkit-scrollbar-track {
    background: rgba(255, 213, 189, 0.8);
}
  
.Map-container .Hint-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 128, 57, 1);
    border-radius: 20px;
    border: 3px solid rgba(255, 213, 189, 0.8);
}