.Court-container {
    
}

.Court-container  .title h2 {
    font-family: "OSM";
    line-height: 21.79px;
    text-align: center; 
}

.Court-container  .show-type {
    max-width: 10%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
}

.Court-container  .show-type span {
    font-family: "OSR";
    font-size: calc(12/16*1em);
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
}

.Court-container  .show-type .active {
    color: #219057;
}

.Court-container  .courts {
    /* Стили для блока с судами */
}