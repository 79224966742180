.Requisites-container{
    margin-bottom: 78px;
}
.Requisites-container .title h2  {
    font-family: "OSM";
    line-height: 21.79px;
    text-align: center;  
}
.Requisites-container .requisites {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.Requisites-container .requisites p {
    line-height: 24px;
}
.Requisites-container .requisites p .moc {
    white-space: nowrap;
}
.Requisites-container .button {
    margin: auto;
    margin-top: 30px;
    text-align: center;
}
.Requisites-container .button a:hover {
    transition: 0.2s;
    transform: scale(110%);
}
.Requisites-container .button a{
    text-transform: uppercase;
    font-family: "OSM";
    line-height: 24px;
    color: #16844B;
    text-decoration: none;
    display: inline-block;
    padding: 25px 40px;
    border: 2px solid;
    border-image-source: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #16844B, #16844B);
    border-radius: 13px;
    transition: 0.2s;
    user-select: none;
}   