

.Form {
    /* стили для формы */
    width: 100%;  
  }
  
  .Form .Form-container {
    /* стили для контейнера формы */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 30px;  

  }
  
  .Form  .Form-column {
    /* стили для колонок формы */
    height: 100%;
    box-sizing: border-box;
  }

  .Form  .label-row {
    /* стили для колонок меток */
    margin-top: 5px;
    color: rgba(132, 132, 132, 1);
    line-height: 21.79px;
  }
  .Form span{
    color: rgba(255, 0, 0, 1);
}
  
  .Form input {
    border: 1px solid rgba(29, 29, 29, 1);
    border-radius: 5px;
    padding: 12px 15px;
    width: 400px;
    font-family: "Inter";
    line-height: 19.36px;
    color: rgba(0, 0, 0, 1);
    font-size: 1em;
  }

  .Form  textarea {
    /* стили для текстовых полей и текстовой области */
    resize: none;
    border: 1px solid rgba(29, 29, 29, 1);
    border-radius: 5px;
    padding: 12px 15px;
    width: 422px;
    height: 210px;
    box-sizing: border-box;
    font-family: "Inter";
    line-height: 19.36px;
    font-size: 1em;
  }
  .Form  textarea:focus, .Form input:focus  {
    box-shadow: 0;
    outline: 1px solid rgba(22, 132, 75, 1); /* Отключение стандартного контура фокуса */
  }
  .Form .input-group {
    margin-top: 10px;
  }
  .Form .input-group:first-child {
    margin-top: 0;
  }
  .Form .Form-footer{ 
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .Form  .form-hint {
    /* стили для подсказки формы */
    
  }
  
  .Form  .form-button {
    /* стили для кнопки формы */
    text-align: right;
  }
  
  .Form  .form-button button {
    font-family: "OSM";
      line-height: 24px;
      color: rgba(22, 132, 75, 1);
      text-decoration: none;
      display: inline-block;
      padding: 10px 37px;
      border: 2px solid rgba(22, 132, 75, 1);
      border-radius: 9px;
      transition: 0.2s;
      user-select: none;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;
  }
  .Form  .form-button button:hover {
    transition: 0.2s;
    transform: scale(110%);
  }