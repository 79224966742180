.OuterData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 21.79px;
    border-bottom: 1px solid #000;
    padding-right: 24px;
}
.OuterData .title {
    display: flex;
    justify-content: space-between;
}
.OuterData a {
    text-decoration: none;
    user-select:text;
    color: #000;
}
.OuterData a:hover, .OuterData a:active, .OuterData a:focus, .OuterData a:visited{
    color: #000;
}
.OuterData .names {
    margin-top: 28px;
    color: #404042;
}
.OuterData .names h1 {
    margin-top: 17px;
    max-width: 338px;
}
.OuterData .contacts {text-align: right;}
.OuterData .contacts div:first-child{
    font-family: "OSB";
    color: #219057;
}

.OuterData .contacts div:last-child{
    margin-top: 6px;
    font-family: "OSL";
}