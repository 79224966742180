.Vacancies {
    max-width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
  
  .Vacancies .title h2 {
    font-family: "OSM";
    line-height: 21.79px;
    margin-left: 25px;
  }
  
  .Vacancies .vac-container {
    /* Стили для контейнера каждой новости */
    margin-top: 25px;
    padding: 25px 25px 30px 25px;
    background: rgba(196, 229, 190, 0.5);
    border-radius: 13px;
  }
  
  .Vacancies .vac-container .vac-header {
    /* Стили для заголовка новости */
    display: flex;
    justify-content: space-between;
  }
  
  .Vacancies .vac-container .vac-header .title {
    /* Стили для заголовка конкретной новости */
    max-width: 70%;
  }
  
  .Vacancies .vac-container .vac-header .title h3 {
    text-transform: uppercase;
    color: rgba(41, 41, 41, 1);
    font-family: "OSM";

  }

  .Vacancies .vac-container .new-header .date {
    /* Стили для даты публикации новости */
    color: rgba(64, 64, 66, 1);
    text-align: right;
  }
  .Vacancies .vac-container article { 
    position: relative;
  }
  .Vacancies .vac-container article p {
    margin-top: 15px;
  }

  .Vacancies .vac-container .vac-button {
    margin-top: 15px;
    text-align: right;
  }
  .Vacancies .vac-container .vac-button button {
      font-family: "OSM";
      line-height: 24px;
      color: rgba(0, 0, 0, 1);
      text-decoration: none;
      display: inline-block;
      padding: 10px 25px;
      border: 1px solid rgba(0, 0, 0, 1);
      border-radius: 9px;
      transition: 0.2s;
      user-select: none;
      background: transparent;
      cursor: pointer;
  }
  .Vacancies .vac-container .vac-button button:hover {
    transition: 0.2s;
    transform: scale(110%);
}
.vac-including .title {
  margin-bottom: 40px;
  margin-top: 10px;
}