@media screen and (max-width: 1850px) {
    .Contacts {
        max-width: 55%;
    }
}
@media screen and (max-width: 1680px) {
    .Contacts {
        max-width: 60%;
    }
}
@media screen and (max-width: 1550px) {
    .Contacts {
        max-width: 65%;
    }
}
@media screen and (max-width: 1440px) {
    .Contacts {
        max-width: 70%;
    }
}
@media screen and (max-width: 1340px) {
    .Contacts {
        max-width: 75%;
    }
}
@media screen and (max-width: 1240px) {
    .Contacts {
        max-width: 80%;
    }
}
@media screen and (max-width: 1160px) {
    .Contacts {
        max-width: 85%;
    }
}
@media screen and (max-width: 1080px) {
    .Contacts {
        max-width: 100%;
    }
}