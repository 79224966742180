.Root {
    max-width: 94.44%;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Root .header-w {

}
.Root .outlet-w {
    flex: 1;
}
.Root .footer-w {
    margin-top: auto;
    margin-bottom: 15px;
}