.Showcase {
    position: relative;
    height: 458px;
    margin-top: 30px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 13px;
    color: #fff;
    position: relative;
    
}
.Showcase .bg {
    user-select: none;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.Showcase .bg img {
    border-radius: 13px;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 458px;
    -webkit-transition: opacity 2s ease-in-out; /* Для Safari и старых версий Chrome */
    -moz-transition: opacity 2s ease-in-out; /* Для Firefox */
    -o-transition: opacity 2s ease-in-out; /* Для Opera */
    -ms-transition: opacity 2s ease-in-out; /* Для Internet Explorer */
    transition: opacity 2s ease-in-out; /* Стандартное значение */
    opacity: 0;
}
.Showcase .bg img.show{
    opacity: 1;
    -webkit-transition: opacity 2s ease-in-out; /* Для Safari и старых версий Chrome */
    -moz-transition: opacity 2s ease-in-out; /* Для Firefox */
    -o-transition: opacity 2s ease-in-out; /* Для Opera */
    -ms-transition: opacity 2s ease-in-out; /* Для Internet Explorer */
    transition: opacity 2s ease-in-out; /* Стандартное значение */
}
.Showcase::before, .Showcase::after{
    content: "";
    position: absolute;
    top: 0;
    width: 45%;
    height: 100%;
    z-index: 0;
    border-radius: 13px;
}
.Showcase::before {
    left: 0;
    background: linear-gradient(90deg, #000000 -54.66%, rgba(0, 0, 0, 0) 92.29%, rgba(0, 0, 0, 0) 100%);
}
.Showcase::after{
    right: 0;
    background: linear-gradient(270deg, #000000 -14.54%, rgba(0, 0, 0, 0.47) 4.08%, rgba(255, 255, 255, 0) 100%);
}
.Showcase .wrapper {
    max-width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.Showcase .advantages {
    max-width: 40%;
    margin-left: 10%;
    font-family: "OSM";
    line-height: 21.79px;
    align-self: center;
}
.Showcase .advantages ul li {
    margin-top: 46px;
}
.Showcase .advantages ul li:first-child {
    margin-top: 0;
}
.Showcase .button-side {
    max-width: 50%;
    margin-right: 36px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.ShowcaseButton {
    margin-bottom: 30px;
    text-align: center;
    display: inline-block;
    padding: 16px 40px;
    border: 2px solid #fff;
    border-radius: 13px;
    background-color: rgba(0, 8, 4, 0.5);
    transition: 0.2s;
}
.ShowcaseButton:hover {
    transition: 0.2s;
    transform: scale(110%);
}
.ShowcaseButton a{
    user-select: none;
    color: #fff;
    text-decoration: none;
}
.ShowcaseButton a div:first-child {
    font-family: "OSB";
    margin-bottom: 2px;
}