.Partners {
    max-width: 78.26%;
    margin: auto;
    margin-top: 27px;
    margin-bottom: 80px;
}

.Partners .title h2 {
    text-align: center;
    font-family: "OSM";
    line-height: 21.79px;
    text-transform: uppercase;
} 

.Partners .slider {
    user-select: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    margin-top: 30px;
} 

.Partners .slider .arrow .left-arrow {
    width: 59px;
    height: 78px;
}

.Partners .slider .logos .slide img{
    transition: all 2s ease;
}
.Partners .slider .logos .slide .hide {

}
.Partners .slider .arrow {cursor: pointer; user-select: none;} 
.Partners .slider .rigt-arrow {
    transform: scaleX(-1);
    width: 59px;
    height: 78px;
}