.About {
    margin: auto;
    margin-top: 50px;
}
.About .Courts {
    margin: auto;
    margin-top: 50px;
}
.About .Description {
    max-width: 67.36%;
    margin: auto;
}
.About .Requisites {
    max-width: 67.36%;
    margin: auto;
    margin-top: 60px;
}
