.Contacts {
    max-width: 50%;
    margin: auto;
    margin-top: 50px;
}
.Contacts .location .title h2 {
    /* Стили заголовка в разделе "location" */
    text-align: center;
    font-family: "OSM";
  }
  .Contacts .location .info  {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }

  .Contacts .location .info .map iframe{
    border-radius: 13px;
    border: 2px solid rgba(132, 132, 132, 1);
    width: 400px;
    height: 400px;
  }
  .Contacts .location .info address {
    width: 50%;
    font-style: normal;
  }
  .Contacts .location .info address .how-to-find .description{
    margin-top: 25px;
  }
  .Contacts .location .info address .how-to-call {
    margin-top: 50px
  } 
  .Contacts .location .info address .how-to-call table tbody tr td:nth-child(1) {
    font-family: "OSM";
} 
  .Contacts .location .info address .how-to-call table tbody tr td:nth-child(2n) {
    /* Стили ячеек таблицы в разделе "how-to-call" */
    padding-left: 10px;
  }
  .Contacts .contacts-form {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .Contacts .contacts-form .title h2 {
    /* Стили заголовка в разделе "contacts-form" */
    text-align: center;
    font-family: "OSM";
  }
  
  .Contacts .contacts-form .form-container {
    /* Стили контейнера формы в разделе "contacts-form" */
    margin-top: 40px;
  }