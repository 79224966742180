.News {
    max-width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
  
  .News .title h2 {
    font-family: "OSM";
    line-height: 21.79px;
    margin-left: 25px;
  }
  
  .News .new-container {
    /* Стили для контейнера каждой новости */
    margin-top: 25px;
    padding: 25px 25px 30px 25px;
    background: rgba(245, 245, 245, 1);
    border-radius: 13px;
  }
  
  .News .new-container .new-header {
    /* Стили для заголовка новости */
    display: flex;
    justify-content: space-between;
  }
  
  .News .new-container .new-header .title {
    /* Стили для заголовка конкретной новости */
    
    max-width: 70%;
  }
  .News .new-container .new-header .title h3 {
    color: rgba(41, 41, 41, 1);
    font-family: "OSM";
  
  }
  .News .new-container .new-header .date {
    /* Стили для даты публикации новости */
    color: rgba(64, 64, 66, 1);
    text-align: right;
  }
  .News .new-container article { 
    position: relative;
  }
  .News .new-container article .text {
    height: 0;
    overflow: hidden;
    transition: height 2s ease;
  }

  .News .new-container article .text.hidden {
    transition: 2s ease;
    height: 0;
  }
  .News .new-container article .text.visible {
    height: auto;
  }
  .News .new-container article p {
    margin-top: 15px;
  }

  .News .new-container .read-next {
    /* Стили для блока "Читать далее" */
    color: rgba(34, 63, 167, 1);
    margin-top: 25px;
    cursor: pointer;
    user-select: none;
  }
  
.News .new-container .read-next span {
    font-family: "OSM";
}