.Success {
    margin: auto;
    text-align: center;
}
.Success .top-text {
    margin: auto;
    margin-top: 25px;
    width: 80%;
}
.Success .icon {
    margin-top: 45px;
    padding-left: 105px;
    padding-right: 105px;
}
.Success .icon img {
    width: 115px;
}
.Success .lower-text {
    margin: auto;
    margin-top: 45px;
    padding-bottom: 20px;
    width: 80%;
}