.Navbar{
    max-width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.Navbar a {
    text-transform: uppercase;
    text-decoration: none;
    color: #000; 
    line-height: 21.79px;
}

.Navbar a, .Navbar a:hover, .Navbar a:active, .Navbar a:focus {
    color: #000;
}