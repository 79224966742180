@import "react-alice-carousel/lib/alice-carousel.css";

@font-face {
  font-family: 'OSB';
  src: local('OpenSans'), url('./fonts/OpenSans.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'OSM';
  src: local('OpenSans'), url('./fonts/OpenSans.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'OSR';
  src: local('OpenSans'), url('./fonts/OpenSans.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'OSL';
  src: local('OpenSans'), url('./fonts/OpenSans.ttf') format('truetype');
  font-weight: 300;
}
* {
  margin: 0; padding: 0;
}
h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: "OSR";
  font-size:  16px;
  font-weight: 400;

}
html {height: 100%;}
body {
  margin: 0; padding: 0;
  height: 100%;
  font-family: "OSR", "OSB", "OSM", "OSL", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}