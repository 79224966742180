.ExamList-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}
.ExamList-container .line {
    max-width: 450px;    
    margin-top: 15px;
    display: flex;
}

.ExamList-container .line div:last-child{
    margin-left: 18px;
}
.ExamList-container .line svg {
    width: 25px;
    height: 25px;
}
