.Modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 13px;
    z-index: 1000;
    padding: 45px;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.1); /* Прозрачный цвет фона */
    backdrop-filter: blur(5px); /* Заблюренный фон */
    z-index: 999;
}
.Modal-wrapper .modal-header {
    display: flex;
    justify-content: space-between;
}
.Modal-wrapper .close-button{
    text-align: right;
    cursor: pointer;
}
.Modal-wrapper .modal-header h3{
    font-family: "OSM";
    line-height: 21.79px;
    text-align: left;
    font-size: 1em;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px;
}
.Modal-wrapper  .close-button svg{
    width: 27px;
    height: 27px;
}