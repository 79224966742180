.Description-container .title h2{
    text-align: center;
    font-family: "OSM";
    line-height: 21.79px;
    text-transform: uppercase;
}
.Description-container .text {
    margin-top: 30px;
}

.Description-container .text p{
    margin-top: 25px;
    font-family: "OSR";
    line-height: 24px;
}
.Description-container .text p:first-child{margin-top: 0;}

.Description-container .text p span {
    color: #16844B;
    font-family: "OSM";
}