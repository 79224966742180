/* Селекторы для стилей компонента NotFound-container */

/* Контейнер, оборачивающий текст и кнопку */
.NotFound-container {
    /* Ваши стили */
    text-align: center;
    width: 30%;
    margin: auto;
    padding-top: 220px;
}

/* Текст заголовка 404 */
.NotFound-container .title h1 {
    /* Ваши стили */
    font-family: "OSB";
    font-size: 5.625em;
    line-height: 122.56px;
    color: rgba(255, 128, 57, 1);
}

/* Подзаголовок */
.NotFound-container .post-title {
    /* Ваши стили */
    font-family: "OSM";
    margin-top: 10px;
    color: rgba(33, 144, 87, 1);
}

/* Описание */
.NotFound-container .description {
    /* Ваши стили */
    margin-top: 20px;
}

/* Обертка для кнопки */
.NotFound-container .button-wrapper {
    /* Ваши стили */
    margin-top: 75px;
}

/* Контейнер кнопки */
.NotFound-container .button-container {
    /* Ваши стили */
    line-height: 21.79px;
    display: inline-block;
    padding: 20px 60px;
    border: 2px solid rgba(22, 132, 75, 1);
    border-radius: 13px;
    transition: 0.2s;
    user-select: none;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase;
}

/* Стили для ссылки (кнопки) */
.NotFound-container .button-container a {
    /* Ваши стили */
    color: rgba(22, 132, 75, 1);
    font-family: "OSR";
    text-decoration: none;
}
.NotFound-container .button-container:hover {
    transition: 0.2s;
    transform: scale(110%);
}