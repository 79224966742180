.Examinations {
    max-width: 67.36%;
    margin: auto;
    margin-top: 50px;
}
.Examinations .lists {
    margin-top: 40px;
}
.Examinations .ps {
    margin-top: 20px;
    margin-bottom: 33px;
    line-height: 40px;
    text-align: center;
}